.react-markdown table {
    border-collapse: collapse;
    width: 100%;
}

.react-markdown table th,
.react-markdown table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.react-markdown table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #6e7481;
    color: white;
}

.custom-quill-editor {
    color: black;
    background-color: white;
}