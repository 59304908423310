.MuiTableHead-root {
    background-color: #6e7481;
}

.MuiTableSortLabel-icon {
    color: white !important;
}

.unselectable {
    user-select: none;
}
.selectedRow {
    background-color: #f0f0f0; /* Replace with your desired color */
}