/* Scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
    width: 10px; /* width of the scrollbar */
    position: absolute; /* position the scrollbar */
    right: 0; /* anchor the scrollbar to the right edge */
  }
  
  /* Track */
  .custom-scrollbar::-webkit-scrollbar-track {
    background: transparent; /* make the track transparent */
  }
  
  /* Handle */
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #e4e3e3; /* color of the scrollbar handle */
    border-radius: 5px; /* roundness of the scrollbar handle */
  }
  
  /* Handle on hover */
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555; /* color of the scrollbar handle on hover */
  }
  