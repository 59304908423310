.ql-editor {
    font-family: 'Roboto', 'Arial', sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    height: calc(100%);
    width: 100%;
    padding: 0 1em 5em 1em;
    border: none;
}

.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
    margin-top: 1em;
    /* margin-bottom: 1em; */
}

.ql-editor p {
    margin-top: 1em;
    margin-bottom: 1em;
}

.ql-tooltip.ql-editing {
    margin-left: 125px;
}

.ql-container.ql-snow {
    border: none;
}

.ql-container {
    height: 100%
}

.edit-view {
    height: calc(100vh - 288px);
    width: 100%;
}

@media (max-width: 1228px) {
    .edit-view {
        height: calc(100vh - 311px);
    }
}