html, body, #app, #app>div {
  height: 100%;
  background-color: #f2f5f8;
}

body {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* App.css */
.AppContainer {
  display: flex;
  max-height: calc(100vh - 70px);
}

.Header {
  flex: 0;
}

.AppContent {
  flex: 1;
  display: flex;
}

.AppContainer, .AppContent {
  min-height: 0;
}

.AppMenu {
  flex: 0;
}

.TTTAppRouterContainer {
  flex: 1;
  overflow: auto;
  max-height: calc(100vh - 125px);
}

.DefaultAppRouterContainer {
  flex: 1;
  overflow: auto;
  padding: 20px 20px;
  margin-bottom: 0px;
  max-height: calc(100vh - 20px);
}

.container {
  display: flex;
  justify-content: space-between;
}

.epics {
  flex: 1;
  margin-right: 1em;
}

.kanban {
  flex: 3;
  display: flex;
  justify-content: space-between;
}

.kanban-column {
  flex: 1;
  margin-right: 1em;
  border: 1px solid #ddd;
  padding: 1em;
  overflow: auto; /* Add this line */
}

/* Scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 10px; /* width of the scrollbar */
  position: absolute; /* position the scrollbar */
  right: 0; /* anchor the scrollbar to the right edge */
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent; /* make the track transparent */
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #e4e3e3; /* color of the scrollbar handle */
  border-radius: 5px; /* roundness of the scrollbar handle */
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* color of the scrollbar handle on hover */
}
